.integer-part {
  text-align: right;
  padding-right: 0;
}

.decimal-part {
  padding-left: 0;
  width: 100px;
}
.totalContainer {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
}
.cartInfo {
  width: 500px;

  div {
    padding-top: 0.5rem;
  }
}

.cart-container {
  width: 500px;
  margin: 0 auto;
}
