.fadeIn {
  opacity: 0;
  transition: opacity 2s ease-in-out;
  animation: fadeIn 2s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
