.stepper_box {
  align-items: baseline;
  padding: 0.5em 0;
  margin: 0em 0em;
  border-radius: 5px;
  color: #20334d;
  width: 100%;

  .chevron-right-wrapper {
    margin-top: 0.25rem;

    @media screen and (min-width: 768px) {
      margin-top: 0;
    }

    svg {
      width: 18px;

      @media screen and (min-width: 768px) {
        width: 25px;
      }
    }
  }
}

.stepper_container {
  margin: 0.8em 0px;
}

@media screen and (max-width: 949px) {
  .stepper_box {
    overflow-x: scroll;
  }
}
