.summary-card {
  .button {
    height: auto;
    padding: 0.4em 1rem;
    font-size: 13px;
    margin-left: 0;

    @media screen and (min-width: 640px) {
      margin-left: 0.5rem;
    }
  }
}

.summary-menu {
  width: 87%;
  position: static;
  margin: 2rem auto;

  @media screen and (min-width: 1024px) {
    position: sticky;
  }

  @media screen and (min-width: 640px) {
    width: 14rem;
  }
}
