.reg_container {
  display: flex;
  margin-bottom: 80px;
  width: 100%;
}

.flex-container {
  display: flex;
  gap: 40px;
  height: 132px;
}

.basis1 {
  flex-basis: calc(33.33% - 10px);
}

.basis2 {
  flex-basis: calc(66.66% - 10px);
}

.ecai-card.big {
  max-width: 18rem;
}

.ecai-card.small {
  max-width: 13rem;
}

.registration-type {
  .ecai-card {
    width: 15.3rem;

    > div {
      aspect-ratio: 1.2;
      border-top-style: solid;
      border-top-width: 3pt;
      background: #f0f7ff 0 0 no-repeat padding-box;
      border-color: #006cff;
      padding: 0.5rem 0.5rem 1rem 0.5rem;
      display: flex;
      justify-content: end;
      flex-direction: column;
      transition: border-width 0.1s linear;
    }

    > div:hover {
      background: #cce1f8 0 0 no-repeat padding-box;
      border-top-width: 10pt;
      cursor: pointer;
      transition: border-width 0.1s linear;
    }

    .selected {
      color: #fff;
      background: #006cff 0 0 no-repeat padding-box;

      div {
        color: #fff;
      }
    }
    .selected:hover {
      background: #006cff 0 0 no-repeat padding-box;
      cursor: default;
    }

    &.variant {
      > div {
        background: #fdfaf7 0 0 no-repeat padding-box;
        border-color: #f0a806;
      }

      > div:hover {
        background: #fcf3e0 0 0 no-repeat padding-box;
      }

      .selected {
        background: #f0a806 0 0 no-repeat padding-box;
      }
      .selected:hover {
        background: #f0a806 0 0 no-repeat padding-box;
      }
    }
  }

  fieldset {
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: #c8c9ca;
    padding: 0.8rem;
    min-height: 4rem;
    margin-bottom: 2rem;

    legend {
      padding: 0 0.5rem;
      font-size: 0.875rem;
    }
  }
}

@media screen and (max-width: 949px) {
  .flex-container {
    flex-direction: column;
    height: auto;
  }
  .basis1 {
    flex-basis: 100%;
  }
  .h1Margin {
    margin-bottom: 30px;
  }
}
