.extra-pages-wrapper {
  .MuiInputBase-root {
    width: 8rem;

    fieldset {
      legend {
        display: none;
      }
    }

    .MuiSelect-select {
      padding: 8.5px 10px;
    }
  }
}

.pages_select {
  width: 85px;
  font-size:13px;
  padding: 4px 10px 5px 10px;
  border: 1px solid gray;
  border-radius: 5px;
  background-color: white;
}

.freePaper{
  position: absolute;
  top: -13px;
  left: 63px;
  background-color: #20334D;
  color: #fff;
  padding: 2px 7px;
  border-radius: 10px;
  font-weight: 600;
}