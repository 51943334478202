.selected_ws {
  background-color: #006cff;
  border-top: solid 3pt #b8b8b8;
  color: #20334d;
  cursor: pointer;
}
.not_selected_ws {
  border-top: solid 3pt #006cff;
  transition: all 0.1s;
  background-color: #f0f7ff;
}
.selected_tu {
  background-color: #f0a806;
  border-top: solid 3pt #b8b8b8;
  color: #20334d;
  cursor: pointer;
}
.not_selected_tu {
  border-top: solid 3pt #f0a806;
  transition: all 0.1s;
  background-color: #fcfbf7;
}

.workshop-selection {
  .ecai-card {
    > div {
      aspect-ratio: 1.8;
      border-top-style: solid;
      border-top-width: 3pt;
      background: #f0f7ff 0 0 no-repeat padding-box;
      border-color: #006cff;
      padding: 1rem;
      display: flex;
      justify-content: end;
      flex-direction: column;
      transition: border-width 0.1s linear;
    }

    > div:hover {
      background: #cce1f8 0 0 no-repeat padding-box;
      border-top-width: 10pt;
      cursor: pointer;
      transition: border-width 0.1s linear;
    }

    .selected {
      color: #fff;
      background: #006cff 0 0 no-repeat padding-box;
    }
    .selected:hover {
      background: #006cff 0 0 no-repeat padding-box;
      cursor: default;
    }
    &.variant {
      > div {
        background: #fdfaf7 0 0 no-repeat padding-box;
        border-color: #f0a806;
      }

      > div:hover {
        background: #fcf3e0 0 0 no-repeat padding-box;
      }

      .selected {
        background: #f0a806 0 0 no-repeat padding-box;
      }
      .selected:hover {
        background: #f0a806 0 0 no-repeat padding-box;
      }
    }
    &.variant2 {
      > div {
        background: #f7fcfa 0 0 no-repeat padding-box;
        border-color: #06f0a7;
      }

      > div:hover {
        background: #e0fcf3 0 0 no-repeat padding-box;
      }

      .selected {
        background: #06f0a7 0 0 no-repeat padding-box;
      }
      .selected:hover {
        background: #06f0a7 0 0 no-repeat padding-box;
      }
    }
  }

  .selection-card {
    color: rgb(0, 108, 255);
    background-color: rgb(255, 255, 255);
    border-color: rgb(0, 108, 255);
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-width: 1px;
    border-radius: 0.375rem;
  }
  .selection-card.active {
    color: #fff;
    background-color: #006cff;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-rows: minmax(0, 1fr);
  gap: 5px 5px;
  justify-content: center;
}

.workshop-chip {
  background-color: #fff;
  position: absolute;
  top: 19px;
  right: 12px;
  border-radius: 17px;
  font-weight: bold;
  border: 2px solid #006cff;

  a {
    display: block;
    width: 100%;
    padding: 0.2rem 0.4rem;
    font-size: 0.7rem;
  }
  a:hover {
    opacity: 0.7;
  }
}

@media screen and (max-width: 949px) {
  .h1Margin {
    margin-bottom: 30px;
  }
}

@media (max-width: 1024px) {
  .grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 640px) {
  .grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
