.addcart {
    display: flex;
    justify-content: center;
    align-items: center;

    
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid gray;
  }
.papersButton{
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #20334d;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #20334d;
}