.middle-title {
  color: gray;
  font-variant: small-caps;

  font-size: 14px;
}

.invoice-container {
  width: 100%;

  @media (min-width: 1024px) {
    width: 800px;
  }
}
