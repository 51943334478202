.side-panel {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background: #f0f7ff;
  padding: 2em 2em 0em 2em;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  color: #20334d;
  width: 100%;

  @media screen and (min-width: 1024px) {
    width: auto;
  }
}

.side-panel.open {
  transform: translateX(0);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}
