@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");

h1,
h2,
h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.html {
  height: 100%;
}
#root {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  padding: 0.75rem 1rem;
  text-align: center;
  height: 45px;
  background-color: #20334d;
  color: white;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.error {
  color: red;
  font-size: 12px;
}

.status {
  background-color: lightgray;
  padding: 1em;
}

.maincnt {
  flex: 1 1 0;

  @media (min-width: 1024px) {
    overflow-y: auto;
  }
}
.main {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}
.stepper {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}
.sectionTitle {
  font-family: "OpenSans", sans-serif;
  font-size: 1.6em;
  font-weight: 700;
}

.lora {
  font-family: "Lora", serif;
}

.bg-footer {
  background-color: #20334d;
  max-width: 1280px;
  margin: 0.5rem auto 0 auto;
  padding-top: 1rem;
  padding-right: 4rem;
  padding-bottom: 1rem;
  padding-left: 4rem;
  height: 75px;
  align-items: center;
}

.bg-custombg {
  background-color: #fff;
}

.chip {
  background-color: #006cff;
  color: #fff;
  font-weight: bold;
  padding: 0.2rem 0.5rem;
  border-radius: 10px;
  line-height: 1;
}

.next-form {
  margin-top: 1rem;

  @media (min-width: 1024px) {
    margin-top: 0.25rem;
  }

  .next-button {
    display: flex;
    height: auto;
    padding: 0.3rem 0.3rem 0.3rem 0.8rem;
    opacity: 1;
    color: #20334d;
    background-color: #f0a806;
  }
}

.registration-logo-wrapper {
  display: flex;
  width: 29rem;
  align-items: center;
  gap: 0rem 2rem;
  flex-flow: row wrap;
  height: 22rem;
  justify-content: center;

  img {
    height: fit-content;
  }

  #ecaiLogo {
    width: 14rem;

    @media (min-width: 380px) {
      width: 18rem;
    }
    @media (min-width: 440px) {
      width: 24rem;
    }
  }
}

.summary-menu {
  margin-top: 2rem;
  width: 14rem;
  position: sticky;
  top: 16px;

  ul {
    border: 1px solid #cce1f8;
    border-radius: 8px;
    padding: 1.5rem 0;
    background: #f0f7ff 0 0 no-repeat padding-box;
    height: max-content;
    min-height: 17rem;

    li {
      font-weight: 600;
      font-size: 0.975rem;
      padding: 0.5rem 1rem;
      cursor: pointer;
    }
    li.selected,
    li:hover.selected {
      background-color: #006cff;
      color: #fff;
    }
    li:hover {
      background: #cce1f8 0 0 no-repeat padding-box;
    }
  }
}

.summary-card {
  padding: 1rem;
  border: 3px solid #f0a806;
  border-radius: 11px;
}

.registration-type {
  h2 {
    text-align: center;
  }
}
