.clicked {
  animation: growAndShrink 0.5s;
}

@keyframes growAndShrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
input:disabled {
  color: gray;
}

.tshirt_select {
  width: 75px;
  font-size:13px;
  padding: 4px 10px 5px 10px;
  border: 1px solid gray;
  border-radius: 5px;
  background-color: white;
}