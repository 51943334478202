.navbar {
  background-color: black;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: end;
  align-items: start;
  padding-left: 16px;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 0 auto 0 auto;
  color: #fff;
}

.boxed {
  max-width: 1280px;
  background-color: #20334d;

}

.message_box {
  max-width: 1280px;
  margin:auto;
}