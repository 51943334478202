.total-cart-chip {
  background-color: #cce1f8;
  color: #20334d;
  padding: 0 10px 1px 10px;
  border-radius: 20px;
  font-size: 14px;
  align-items: center;
  display: flex;
}

.clicked {
  animation: growAndShrink 0.5s;
}

@keyframes growAndShrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
